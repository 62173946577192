:root {
	--color-primary: #3E5F75;
	--color-primary-light: #B4CDDE;
	--color-primary-dark: #113B56;
	--color-blue-dark: #101A37;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #ccc;
	--color-grey-dark-1: #333;
	--color-grey-dark-2: #777;
	--color-grey-dark-3: #999;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0;
	padding: 0;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%;
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	} 
}
body {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
}

/* APP */
.app {
	width: 100%;
	min-height: 100vh;
}
.app nav {
	display: flex;
	background-color: var(--color-blue-dark);
}
.app nav a {
	flex: 1;
	padding: 2rem;
	color: #fff;
	font-size: 1.4rem;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
}
.app nav a:hover {
	background-color: var(--color-primary-dark);
}

/* PROJECTS */
.projects {
	padding: 4.5rem;
}
.projects form {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 40rem;
}
.projects__input-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font-size: 1.4rem;
	color: var(--color-blue-dark);
	text-transform: uppercase;
}
.projects__input-container input {
	padding: 1rem;
	border: none;
	border-bottom: 1px solid var(--color-primary-dark);
	outline: none;
}
.projects__input-container button {
	padding: 1rem;
	border: none;
	background-color: var(--color-primary-dark);
	color: #fff;
	cursor: pointer;
	outline: none;
	text-transform: uppercase;
}
.projects table {
	width: 100%;
	margin-top: 4.5rem;
	border-collapse: collapse;
}
.projects table th {
	background-color: var(--color-primary-dark);
	color: #fff;
	text-transform: uppercase;
	padding: 1rem;
}
.projects table td {
	padding: 1rem;
	border-bottom: 1px solid var(--color-primary);
	text-align: center;
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
}
.projects table td img {
	max-width: 5rem;
}
.projects table td a {
	text-decoration: none;
	color: var(--color-primary);
}

/* SINGLE-PROJECT */
.single-project {
	padding: 4.5rem;
	display: flex;
	flex-direction: column;
}
.single-project__input-container {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	font-size: 1.4rem;
	color: var(--color-primary-dark);
}
.single-project__input-container input {
	padding: 1rem;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid var(--color-primary-dark);
	outline: none;
}
.single-project__input-container textarea {
	height: 30rem;
	background-color: transparent;
	border: 1px solid var(--color-primary-dark);
	outline: none;
	padding: 1rem;
	border-radius: 1rem;
	margin-top: 1rem;
}
.single-project__input-container button {
	margin-top: auto;
	cursor: pointer;
	padding: 1rem;
	background-color: var(--color-primary-dark);
	color: #fff;
	outline: none;
	border: none;
}
.single-project__logo {
	display: flex;
}
.single-project__logo form {
	flex: 0 0 25%;
	display: flex;
	flex-direction: column;
	padding: 2rem;
	justify-content: center;
}
.single-project__logo picture {
	flex: 0 0 25%;
	padding: 2rem;
}
.single-project__logo picture img {
	width: 100%;
}
.single-project__details {
	display: flex;
	flex-wrap: wrap;
	padding: 4.5rem 0;
}
.single-project__details .single-project__input-container {
	flex: 0 0 50%;
}
.single-project__images {
	padding: 4.5rem 0;
}
.single-project__images form {
	display: flex;
}
.single-project__images form .single-project__input-container {
	flex: 1;
}
.single-project__images ul {
	display: flex;
	margin-top: 2.5rem;
	list-style: none;
	overflow-x: auto;
}
.single-project__images ul li {
	flex: 0 0 15%;
	padding: .5rem;
	display: flex;
	flex-direction: column;
}
.single-project__images ul li a {
	font-size: 2rem;
	color: var(--color-primary-dark);
	text-decoration: none;
}
.single-project__images ul li img {
	width: 100%;
}
.single-project__images ul li button {
	border: none;
	background-color: red;
	color: #fff;
	margin-top: .5rem;
	cursor: pointer;
	border-radius: 3px;
}
.single-project__images ul.highlight {
	flex-direction: column;
}
.single-project__images ul.highlight li {
	flex: unset;
	padding: 2rem;
	border: 1px solid var(--color-primary-dark);
	margin-top: 1rem;
}
.single-project__images ul.highlight li button {
	margin-left: auto;
}

/* CAMPAIGNS */
.campaigns {
	padding: 4.5rem;
}
.campaigns form {
	display: flex;
}
.campaigns__input-container {
	flex: 1;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	text-transform: uppercase;
	justify-content: space-between;
}
.campaigns__input-container input {
	padding: 1rem;
	border: none;
	border-bottom: 1px solid var(--color-primary-dark);
	outline: none;
}
.campaigns__input-container button {
	margin-top: auto;
	padding: 1rem;
	background-color: var(--color-primary-dark);
	color: #fff;
	cursor: pointer;
	border: none;
	outline: none;
}
.campaigns table {
	margin-top: 4.5rem;
	border-collapse: collapse;
	width: 100%;
	font-size: 1.4rem;
}
.campaigns table th {
	padding: 1rem;
	background-color: var(--color-primary-dark);
	color: #fff;
	text-transform: uppercase;
}
.campaigns table td {
	padding: 1rem;
	border-bottom: 1px solid var(--color-primary-dark);
}
.campaigns table td a {
	color: var(--color-primary);
}

/* SINGLE-CAMPAIGN */
.single-campaign {
	padding: 4.5rem;
}
.single-campaign__details {
	list-style: none;
	padding: 0 2rem;
	font-size: 1.4rem;
	color: var(--color-primary-dark);
}
.single-campaign__details li {
	margin-top: 2rem;
}
.single-campaign__details li a {
	color: var(--color-primary);
	text-decoration: none;
}
.single-campaign__modify {
	display: flex;
	flex-direction: column;
	max-width: 60rem;
	margin-top: 2.5rem;
}
.single-campaign__input-container {
	display: flex;
	flex-direction: column;
	padding: 2rem;
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	text-transform: uppercase;
}
.single-campaign__input-container input,
.single-campaign__input-container select,
.single-campaign__input-container textarea {
	padding: 1rem;
	border: none;
	background-color: transparent;
	border-bottom: 1px solid var(--color-primary-dark);
	outline: none;
}
.single-campaign__input-container button {
	margin-top: 1rem;
	padding: 1rem;
	border: none;
	background-color: var(--color-primary-dark);
	color: #fff;
	cursor: pointer;
	outline: none;
}
.single-campaign__action-plan {
	padding: 9rem 0;
}
.single-campaign__action-plan form {
	display: flex;
}
.single-campaign__action-plan form .single-campaign__input-container {
	flex: 1;
}
.single-campaign__action-plan form .single-campaign__input-container button {
	margin-top: auto;
}
.single-campaign__action-plan table {
	width: 100%;
	max-width: 60rem;
	border-collapse: collapse;
	font-size: 1.4rem;
}
.single-campaign__action-plan table th {
	padding: 1rem;
	background-color: var(--color-primary-dark);
	color: #fff;
	text-transform: uppercase;
}
.single-campaign__action-plan table td {
	padding: 1rem;
	border-bottom: 1px solid var(--color-primary-dark);
}